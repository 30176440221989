form {
    width: 100%;
    height: 100%;
    margin: 0 auto; /* Center the form horizontally */
    padding: 5px;
    z-index: 1;
    overflow: hidden;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

textarea {
    height: 250px; /* Increase height for the textarea */
}

input[type="submit"] {
    background-color: var(--toggle-bg);
    font-family: var(--font-family-body);
    color: var(--primary-text-color);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

input[type="submit"]:hover {
    color: var(--background-color);
}