* {
    /*
    border: 1px solid var(--foreground-color); 
    */
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0; /* Remove any default margin */
}

/* App Container Styles */
.App {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    transition: background-color 0.25s ease-in-out;
    box-sizing: border-box;
}

/* Header Styles */
.App-header {
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 15px 20px;
    position: sticky;
    z-index: 1;
}

/* Navigation Styles */
nav {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 1rem;
}

nav a {
    padding: 10px;
    text-decoration: none;
    font-family: var(--font-family-body);
    font-weight: 400;
    color: var(--primary-text-color);
}

nav a:hover {
    text-decoration: underline;
}

/* Position the landing page section */
.landing-page {
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    color: var(--background-color);
    font-family: var(--font-family-hero);
    overflow-x: hidden;
    z-index: 1; /* Ensure content is above the background */
}

.hero-text {
    padding-bottom: 15%; 
    width: 65vw; 
    max-width: 70%;
    z-index: 1;
}

/* Hero text styling */
.hero-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    background-color: var(--toggle-bg);
}

.hero-text p {
    font-size: 1.25rem;
    font-weight: 400;
    background-color: var(--toggle-bg);
}

/* Content Area Styles */
.content {
    width: 99%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center; /* Vertically center */
    align-items: center; /* Horizontally center */
    text-align: center; /* Center text inside content */
    padding: 15px;
    color: var(--primary-text-color);
    font-family: var(--font-family-body);
    margin: 5px;
    overflow-y: auto;
    gap: 3rem;
}

/* Toggle Styles */
.toggle {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
    gap: 10px;
    z-index: 1;
}

.toggleLabel {
    color: var(--primary-text-color);
    font-family: var(--font-family-body);
    font-weight: 400;
}

.toggle input[type="checkbox"] {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    appearance: none;
    outline: none;
    border: 1px solid var(--foreground-color);
    border-radius: 2px;
    background-color: var(--background-color);
}

.toggle input[type="checkbox"]:checked {
    background-color: var(--toggle-bg);
}

.toggle input[type="checkbox"]::before {
    content: "";
}

.toggle input[type="checkbox"]:checked::before {
    visibility: visible;
}

/* Logo Styles */
.logo {
    font-family: var(--font-family-hero);
    font-size: 44px;
    text-decoration: none;
    color: var(--primary-text-color);
    z-index: 1;
}

.projects-container {
    position: relative;
    width: 100%;
}

.projects-header {
    position: absolute;
    top: 20px; /* Adjust this value to control how far down the header is moved */
    left: 0;
    width: 100%; /* Ensure it spans the full width */
}

/* Projects Grid Styles */
.projects-grid {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 5px 0;
    z-index: 1;
    justify-content: center; 
    align-items: center;

}

.project {
    display: flex;
    width: 33%;
    height: 33%;
    box-sizing: border-box;
    padding: 10px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: transform 250ms;
}

.project img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.project:hover {
    transform: translateY(-10px);
}

/* Services Header Styles */
.services-header {
    text-align: center;
    margin-bottom: 1rem;
    color: var(--primary-text-color);
}

.services-header h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-family: var(--font-family-hero);
}

.services-header p {
    font-size: 1.125rem;
    max-width: 600px;
    margin: 0 auto;
    color: var(--primary-text-color);
}

/* Services Grid Styles */
.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 3rem;
    width: 85%;
}

.service-card {
    background: var(--background-color);
    border: 1px solid var(--foreground-color);
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.2s ease;
    z-index: 1;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-card h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-family: var(--font-family-hero);
}

.service-card p {
    color: var(--primary-text-color);
}

/* CTA Section Styles */
.cta-section {
    border: 1px solid var(--foreground-color);
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    z-index: 1;
    width: 90%;
    box-sizing: border-box;
    background-color: var(--toggle-bg);
}

.cta-section h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-family: var(--font-family-hero);
}

.cta-section p {
    max-width: 600px;
    margin: 0 auto 1.5rem auto;
}

.cta-button {
    display: inline-block;
    background: var(--primary-text-color);
    color: var(--toggle-bg);
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.2s ease;
}

.cta-button:hover {
    background: var(--background-color);
}

/* Background Styles */
.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    overflow-y: hidden;
}

/* Contact Styles */
.contact {
    display: flex;
}

/* Social Icons Styles */
.social-icons {
    height: 200px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons a {
    color: var(--foreground-color);
    font-size: 3.5rem;
    line-height: 2rem;
    margin-right: 10px;
    transition: transform 250ms;
}

.social-icons a:hover {
    transform: translateY(-10px);
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .hero-text {
        max-width: 90%;
    }
    
    .projects-grid {
        width: 95%;
    }
    
    .services-grid {
        width: 100%;
    }
}

@media (max-width: 768px) {
    /* Stack header items */
    .App-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 10px 15px;
    }

    /* First row: About, Logo, Contact */
    .header-top {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .logo {
        font-size: 1.5rem;
        flex-grow: 1;
        text-align: center;
    }
    
    .mobile-menu {
        display: flex;
    }
    
    .hero-text h1 {
        font-size: 2rem;
    }
    
    .hero-text p {
        font-size: 1.1rem;
    }
    
    .project {
        width: 45%;
    }
    
    .services-grid {
        grid-template-columns: 1fr;
    }
    
    .cta-section {
        padding: 1.5rem;
    }
}

@media (max-width: 500px) {
    .hero-text h1 {
        font-size: 1.75rem;
    }
    
    .hero-text p {
        font-size: 1rem;
    }
    
    .project {
        width: 100%;
    }
    
    .social-icons a {
        font-size: 2rem;
    }
}
