* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --background-color: #eef1e4;
    --foreground-color: #2B2729;
    --primary-text-color: #293132;
    --secondary-text-color: #4A0202;
    --toggle-bg: #719cc6;

    --font-family-body: "Space Mono", monospace;
    --font-family-hero: "Krona One", sans-serif;
}

[data-theme="dark"] {
    --background-color: #2B2729;
    --foreground-color: #eef1e4;
    --primary-text-color: #eef1e4;
    --secondary-text-color: #ffeedb;
    --toggle-bg: #8783d1;
}